import React from 'react';
import PropTypes from 'prop-types';

import { CoreRouter, LogoutRoute, DefaultRoute, AuthRoute } from './utils/coreRouter';
import Login from '../pages/Login';
import ForgottentPassword from '../pages/ForgottenPassword';
import ResetPassword from '../pages/ResetPassword';
import Signup from '../pages/SignUp';
import horoscopeGenerated from '../pages/Horoscope/horoscopeGenerate';
import orderConfirm from '../pages/OrderConfirm/orderConfirm';
import orderComplete from '../pages/Horoscope/orderComplete';
import MoonsignSelect from '../pages/Horoscope/moonsignSelect';
import PaymentPlanSelect from '../pages/Horoscope/paymentPlanSelect';
import EnterPaymentDetails from '../pages/EnterPaymentDetails/enterPaymentDetails';
import SingleHoroscope from '../pages/SingleHoroscope';
import Dashboard from '../pages/Dashboard';
import MySubscriptions from '../pages/MySubscriptions';
import MyProfile from '../pages/Profile';
import Consultations from '../pages/Consultations';
import ConsultationDetail from '../pages/ConsultationDetail';
import ConsultationForm from '../pages/ConsultationForm';
import ConsultationConfirmation from '../pages/ConsultationConfirmation';
import OneOffConfirmation from '../pages/OneOffConfirmation';
import OneOffConfirmationMobileSuccessfull from '../pages/OneOffConfirmationMobileSuccessfull';
import EnterPaymentMobile from '../pages/EnterPaymentMobile';
import Noticeboard from '../pages/Noticeboard';
import LifeEvents from '../pages/LifeEvents';
import LifeEventConfirmation from '../pages/LifeEventConfirmation';
import LifeEventView from '../pages/LifeEventView';
import EnterLifeEventPaymentMobile from '../pages/EnterLifeEventPaymentMobile';
import Gemstones from '../pages/Gemstones';
import Gemstone from '../pages/Gemstone';
import GemstoneEnquiry from '../pages/GemstoneEnquiry';
import GemstoneEnquirySuccess from '../pages/GemstoneEnquirySuccess';

const Routes = ({ persistor }) => (
    <CoreRouter>
        <DefaultRoute exact path="/" component={Login} />
        <DefaultRoute exact path="/signup" component={Signup} />
        <DefaultRoute exact path="/horoscope-generated" component={horoscopeGenerated} />
        <DefaultRoute exact path="/order-confirm" component={orderConfirm} />
        <DefaultRoute exact path="/order-complete" component={orderComplete} />
        <DefaultRoute exact path="/moonsign-select" component={MoonsignSelect} />
        <DefaultRoute exact path="/payment-plan-select" component={PaymentPlanSelect} />
        <DefaultRoute exact path="/enter-payment-details" component={EnterPaymentDetails} />
        <DefaultRoute exact path="/enter-payment-mobile/:questions?/:consultationId?/:authToken?" component={EnterPaymentMobile} />
        <DefaultRoute exact path="/enter-life-event-payment-mobile/:lifeEventIds?/:authToken?" component={EnterLifeEventPaymentMobile} />
        <DefaultRoute exact path="/horoscope/:timePeriod/:horoscopeSign" component={SingleHoroscope} />
        <DefaultRoute exact path="/forgot-password" component={ForgottentPassword} />
        <DefaultRoute exact path="/reset-password/:token/:email" component={ResetPassword} />
        <DefaultRoute exact path="/life-events" component={LifeEvents} />
        <DefaultRoute exact path="/life-event-confirmation" component={LifeEventConfirmation} />
        <DefaultRoute exact path="/life-event-view" component={LifeEventView} />
        <DefaultRoute exact path="/gemstones" component={Gemstones} />
        <DefaultRoute exact path="/gemstone/:slug" component={Gemstone} />
        <DefaultRoute exact path="/gemstone-enquiry" component={GemstoneEnquiry} />
        <DefaultRoute exact path="/gemstone-enquiry-success" component={GemstoneEnquirySuccess} />
        <AuthRoute exact path="/dashboard/:tabId?" component={Dashboard} />
        <AuthRoute exact path="/my-subscriptions" component={MySubscriptions} />
        <AuthRoute exact path="/profile" component={MyProfile} />
        <AuthRoute exact path="/auth/dashboard" component={Dashboard} />
        <AuthRoute exact path="/noticeboard" component={Noticeboard} />
        <DefaultRoute exact path="/consultations" component={Consultations} />
        <DefaultRoute exact path="/consultation-detail/:url?" component={ConsultationDetail} />
        <AuthRoute exact path="/consultation-form" component={ConsultationForm} />
        <AuthRoute exact path="/consultation-confirmation" component={ConsultationConfirmation} />
        <AuthRoute exact path="/one-off-confirmation" component={OneOffConfirmation} />
        <DefaultRoute exact path="/one-off-confirmation-mobile/:horoscopeId?/:authToken?" component={OneOffConfirmation} />
        <DefaultRoute exact path="/one-off-confirmation-mobile-successfull" component={OneOffConfirmationMobileSuccessfull} />
        <LogoutRoute exact path="/logout" persistor={persistor} />
    </CoreRouter>
);

Routes.defaultProps = {
    persistor: null,
};

Routes.propTypes = {
    persistor: PropTypes.object,
};

export default Routes;
