/* eslint-disable react/no-danger */

import React, { useEffect, useState } from 'react';
import { Tabs, Row, Col, Spin, Empty } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, findIndex } from 'underscore';
import moment from 'moment';
import dompurify from 'dompurify';
import SubscriptionButton from '../../components/SubscriptionButton';
import { getHoroscopeDataRange, getSelectedTranslation, getTranslation } from '../../core/utils/helpers';
import Main from '../../components/layout/Main';
import bellBadgedImg from '../../assets/images/clarity_bell-solid-badged.svg';
import bellImg from '../../assets/images/clarity_bell-solid.svg';
import YellowThemeButton from '../../components/YellowThemeButton';

import {
    userActiveHoroscopesRequest,
    // selectSingleHoroscopeRequest,
    checkIfLiveRequest,
    userPreviousHoroscopesRequest,
} from '../../core/horoscope/horoscopeActions';

import {
    getUserDetailsRequest,
} from '../../core/user/userActions';

import {
    noticeboardRequest,
} from '../../core/noticeboard/noticeboardActions';

import history from '../../core/utils/history';

import './dashboard.scss';

export const WEEKLY_TAB_ID = '1';
export const MONTHLY_TAB_ID = '2';
export const YEARLY_TAB_ID = '3';

const { TabPane } = Tabs;
const Dashboard = () => {
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const horoscope = useSelector(state => state.horoscope);
    const language = useSelector(state => state.language);
    const noticeboard = useSelector(state => state.noticeboard);
    const [selectedTab, setSelectedTab] = useState(WEEKLY_TAB_ID);

    const location = useLocation();

    useEffect(() => {
        dispatch(userActiveHoroscopesRequest());
        dispatch(getUserDetailsRequest());
        dispatch(checkIfLiveRequest());
        dispatch(noticeboardRequest());
    }, []);

    useEffect(() => {
        if (horoscope.hasFetchedHorosopes) {
            dispatch(userPreviousHoroscopesRequest());
        }
    }, [horoscope.hasFetchedHorosopes]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const tab = searchParams.get('tab');
        if (tab) {
            setSelectedTab(tab);
        } else {
            setSelectedTab(WEEKLY_TAB_ID);
        }
    }, [location.search]);

    const getCurrentHoroscopeTimePeriod = (currentHoroscope) => {
        if (currentHoroscope?.current_period.cycle === 1) {
            return 'weekly';
        }
        if (currentHoroscope?.current_period.cycle === 2) {
            return 'monthly';
        }
        return 'yearly';
    };

    const selectSingleHoroscope = (currentHoroscope) => {
        const timePeriod = getCurrentHoroscopeTimePeriod(currentHoroscope);
        const horoscopeKey = currentHoroscope?.key?.toLowerCase();
        history.push(`/horoscope/${timePeriod}/${horoscopeKey}`);
    };

    const selectingLifeEvents = () => {
        history.push('/life-events');
    };

    const handleTabChange = (key) => {
        // setSelectedTab(key);
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('tab', key);
        history.push({ search: searchParams.toString() });
    };

    const hasUserSubscription = (userTabId) => {
        let potentialPlans = [];

        if (userTabId === 1) {
            potentialPlans = [1, 2, 3];
        }

        if (userTabId === 2) {
            potentialPlans = [2, 3];
        }

        if (userTabId === 3) {
            potentialPlans = [3];
        }

        const subIndex = findIndex(user.userData?.active_subscriptions, val => potentialPlans.includes(val.payment_plan.horoscope_content_frequency));
        if (subIndex !== -1) {
            return true;
        }

        return false;
    };

    const toolbar = [{ label: 'Item 1', link: '/abc', iconName: 'home' }];
    const menu = [{ label: 'Item 1', link: '/abc', iconName: 'home', style: { fontSize: 24, color: 'red' } }];

    const renderSingleHoroscope = (sTH, cycle) => {
        const data = sTH?.horoscope;
        const sanitizer = dompurify.sanitize;

        const horoscopeTranslation = getSelectedTranslation(data?.translations, language?.selectedLanguage?.id);
        const horoscopeDataTranslation = getSelectedTranslation(data?.horoscopeData?.translations, language?.selectedLanguage?.id);
        const userSubscribedClass = data.is_user_subscribed ? 'user-subscribed' : '';

        if (isEmpty(sTH)) {
            return <></>;
        }

        let period = null;

        if (cycle === 'weekly') {
            period = (
                <p className="horoscope-subtitle"> { data?.date ? moment(data?.date).format('MMMM D ') : '' }
                    - { data?.date ? moment(data?.date).add(6, 'days').format('MMMM D ') : '' }
                </p>
            );
        }
        if (cycle === 'monthly') {
            period = (
                <p className="horoscope-subtitle"> { data?.date ? moment(data?.date).format('MMMM') : '' }</p>
            );
        }
        if (cycle === 'yearly') {
            period = (
                <p className="horoscope-subtitle"> { data?.date ? moment(data?.date).format('YYYY') : '' }</p>
            );
        }

        if (cycle === 'yearly') {
            return (
                <div className={`horoscope-item ${userSubscribedClass}`} key={data.id}>
                    <div className="moonsign">
                        <h4 className="horoscope-title">{horoscopeTranslation?.name}</h4>
                        <h5 className="horoscope-subtitle">{getHoroscopeDataRange(data)}</h5>
                    </div>

                    <div className="horoscope-description">
                        <p>{horoscopeDataTranslation?.short_description}</p>
                    </div>
                    <div className="horoscope-image">
                        <img src={data.mediaUrl} alt="moonsign" />
                    </div>
                    <div className="buttons">
                        {data.is_user_subscribed || data.has_one_off_purchase
                            ? (
                                <div className="subscribed-button-wrapper">
                                    <YellowThemeButton type="button" onClick={() => selectingLifeEvents(data)}>{getTranslation(language, 'life_events', 'Life Events')}</YellowThemeButton>
                                </div>
                            ) : (
                                <>
                                    <YellowThemeButton type="button" onClick={() => selectSingleHoroscope(data)}>{getTranslation(language, 'horoscopes', 'Horoscopes')}</YellowThemeButton>
                                    <YellowThemeButton type="button" onClick={() => selectingLifeEvents(data)}>{getTranslation(language, 'life_events', 'Life Events')}</YellowThemeButton>
                                </>
                            )}

                    </div>
                </div>
            );
        }

        return (
            <div className={`horoscope-item ${userSubscribedClass}`} key={data.id} onClick={() => selectSingleHoroscope(data)}>
                <Row wrap>
                    <Col xs={24} sm={8} md={8} lg={8} xl={8} className="left-col">
                        <img src={data?.mediaUrl} alt={horoscopeTranslation?.name} />
                    </Col>
                    <Col xs={24} sm={16} md={16} lg={16} xl={16} className="right-col">
                        <h4 className="horoscope-title">{horoscopeTranslation?.name}</h4>
                        {period}
                        <p dangerouslySetInnerHTML={{ __html: sanitizer(horoscopeDataTranslation?.short_description) }} />
                        <div className="actions link-to-horoscope">
                            {getTranslation(language, 'horoscopes_listing_screen_read_more_btn_title', 'Read more')}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    };

    return (
        <Main
            title={<div>Welcome</div>}
            headerTitle={<div> <img style={{ height: 70, width: 70 }} src="https://prakashastrologer-19e87.kxcdn.com/wp-content/uploads/2020/05/PrakashAstrologerLogo.svg" alt="logo" /></div>}
            menu={menu}
            menuWidth={350}
            menuPlacement="right"
            toolbar={toolbar}
            floatingHeader
            showFooter={false}>
            <div className="dashboard-container">
                <div className="signup-button">

                    <div style={{ width: '100%', textAlign: 'right' }}>
                        {!hasUserSubscription(selectedTab) ? (
                            <SubscriptionButton selectDefaultUserHoroscope horoscopeContentFrequency={selectedTab} />
                        ) : <></>}
                    </div>

                    <Link to={{ pathname: '/noticeboard', state: { notices: selectedTab } }} className="bell-icon">
                        {noticeboard.noticeboardDataAllread
                            ? <img src={bellImg} alt="notification all read" />
                            : <img src={bellBadgedImg} alt="notification not read" />}
                    </Link>
                </div>
                <div className="content">
                    <Tabs className="theme-tabs"
                        defaultActiveKey="1"
                        activeKey={selectedTab}
                        onChange={(key) => handleTabChange(key)}>
                        <TabPane tab={getTranslation(language, 'horoscopes_listing_screen_tab_weekly_title', 'Weekly')} key={WEEKLY_TAB_ID}>
                            {horoscope?.isFetchingInitial === true
                                ? (
                                    <div className="loaderSpacer">
                                        <Spin size="large" />
                                    </div>
                                )
                                : (
                                    <div className="horoscope-list">
                                        {!isEmpty(horoscope.userActiveHoroscopes.Weekly) ? horoscope.userActiveHoroscopes.Weekly.map(sTH => renderSingleHoroscope(sTH, 'weekly'))
                                            : <Empty style={{ marginTop: 100 }} description={getTranslation(language, 'horoscopes_listing_screen_no_horoscopes_label', 'There are no horoscopes.')} />}
                                    </div>
                                )}

                        </TabPane>
                        <TabPane tab={getTranslation(language, 'horoscopes_listing_screen_tab_monthly_title', 'Monthly')} key={MONTHLY_TAB_ID}>
                            {horoscope?.isFetchingInitial === true
                                ? (
                                    <div className="loaderSpacer">
                                        <Spin size="large" />
                                    </div>
                                )
                                : (
                                    <div className="horoscope-list">
                                        {!isEmpty(horoscope.userActiveHoroscopes.Monthly) ? horoscope.userActiveHoroscopes.Monthly.map(sTH => renderSingleHoroscope(sTH, 'monthly'))
                                            : <Empty style={{ marginTop: 100 }} description={getTranslation(language, 'horoscopes_listing_screen_no_horoscopes_label', 'There are no horoscopes.')} />}
                                    </div>
                                )}

                        </TabPane>
                        <TabPane tab={getTranslation(language, 'horoscopes_listing_screen_tab_yearly_title', 'Yearly')} key={YEARLY_TAB_ID}>
                            {horoscope?.isFetchingInitial === true
                                ? (
                                    <div className="loaderSpacer">
                                        <Spin size="large" />
                                    </div>
                                )
                                : (
                                    <div className="horoscope-list">
                                        {!isEmpty(horoscope.userActiveHoroscopes.Yearly) ? horoscope.userActiveHoroscopes.Yearly.map(sTH => renderSingleHoroscope(sTH, 'yearly'))
                                            : <Empty style={{ marginTop: 100 }} description={getTranslation(language, 'horoscopes_listing_screen_no_horoscopes_label', 'There are no horoscopes.')} />}
                                    </div>
                                )}

                        </TabPane>
                    </Tabs>
                </div>
            </div>

        </Main>
    );
};

export default Dashboard;
